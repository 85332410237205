import React from 'react';
import { isMobile } from "react-device-detect";
import PlatformVersion from '../Global/PlatformVersion';
import CopyRight from '../Global/CopyRight';
import { ListItem, Toolbar} from '@material-ui/core';
import {omni_trust_center} from '../../Config/config'

function LoginFooter(props) {

  if (isMobile){
    return (
        <footer style={{boxSizing: 'border-box', padding: '5px', textAlign: 'center', borderTop: "solid 1px #c9cbce", fontSize: '0.8rem', width: 100 + "%", backgroundColor: "white", fontFamily: "Lato", position: "absolute", bottom: 0, zIndex: 0, ...props.styles }}>
            {/* <div style={{ marginLeft: "35%" }}>
                <a href="https://vlomni.com/omni-trust-center/" target="_blank" style={{ color: "#6d6e70", textDecoration: "none" }}>OMNI Trust Center</a>
            </div>
            <div style={{ marginLeft: "15%", marginTop: -5 }}>
                <p style={{ color: "#6d6e70" }}>© Copyright 1996-2019, VL Virtual Logistics Inc.</p>
            </div> */}
            <div style={{ }}>
                <a href={omni_trust_center} target="_blank" rel="noopener noreferrer" style={{ color: "#6d6e70", textDecoration: "none" }}>OMNI Trust Center</a>
            </div>
            <div style={{ }}>
                <p style={{ color: "#6d6e70", margin: '0' }}><CopyRight/></p>
            </div>
        </footer>       
    )
  }else{
    return (
        <footer style={{ maxHeight: 50, fontSize: 14, width: 100 + "%", backgroundColor: "white", fontFamily: "Lato", position: "absolute", bottom: 0, borderTop: "solid 1px #c9cbce" }}>
            <Toolbar style={{ paddingLeft: 3.25 + "%", paddingRight: 2.25 + "%" }}>
                <ListItem style={{ paddingLeft: 2.25 + "%", paddingRight: 2.25 + "%", marginTop: -45 }}>
                    <p style={{ color: "#6d6e70" }}><PlatformVersion/></p>
                </ListItem>
                <ListItem style={{ paddingLeft: 10 + "%", marginLeft: 3 + "%", paddingRight: 2.25 + "%", marginTop: -45  }}>
                    <a href={omni_trust_center} target="_blank" rel="noopener noreferrer" style={{ color: "#6d6e70", textDecoration: "none" }}>OMNI Trust Center</a>
                </ListItem>
                <ListItem style={{ paddingLeft: 5 + "%", paddingRight: 0, marginLeft: 2 + "%", marginRight: -2 + "%", marginTop: -45  }}>
                    <p style={{ color: "#6d6e70" }}><CopyRight/></p>
                </ListItem>
            </Toolbar>
        </footer>       
    )
  }
}

export default LoginFooter;