import React, { Component, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import MutationObserver from 'react-mutation-observer';
import HttpsRedirect from 'react-https-redirect';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@mui/material'

import './App.css';
import LoginPage from './LoginPage';
import ASCAuthS1 from './ASCAuth/Step1';
import ASCAuthS2 from './ASCAuth/Step2';
import {returnAccess} from './utility/returnAccess'
import NewUser from './NewUser';
import ConfirmUser from './ConfirmUser';
import {returnCookieValue} from './utility/returnCookieValue';
import {dropCookie} from './utility/dropCookie';
import * as config from './Config/config';
import {resetStorage} from './utility/resetStorage';
import CookieDrop from './components/CookieDrop/CookieDrop'
import ErrorBoundary from './hoc/ErrorBoundary/ErrorBoundary'
import ErrorPage from './components/PlaceHolder/Error';
import {secureStorage_getItem} from './utility/secureStorage';

const NavWrapper = React.lazy(()=> import('./NavWrapper'))
const NDNavWrapper = React.lazy(()=> import('./Dashboardv3.0/newComponents/NavWrapper'))

const drawerWidth = 240;

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        minHeight:'100vh'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarShiftMobile: {
        marginLeft: 60,
        width: `calc(100% - 60px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    drawerPaperMobile: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 60,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        height:'100%'
    },
    drawerPaperMobileIOS: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 40,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    drawerPaperCloseMobile: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },
    },
    drawerPaperCloseMobileIOS: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0',
        width: '100%',
        ...theme.mixins.toolbar,
    },
    toolbarIOS: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: "#f4f4f4",
        padding: theme.spacing(1),
    },
});

class App extends Component {

  once(fn, context){
    var result;
    return function(){
      if(fn){
        result = fn.apply(context||this, arguments);
        fn=null;
      }

      return result;
    }
  }

  check = () => {
    let now = new Date()
    let last_active_time = returnCookieValue('vlomni-cookie-active');  
    // last_active_time = new Date(Date.parse(last_active_time))
    let result = true
    
    if (returnCookieValue('vlomni-cookie-active') && (now.getTime() - last_active_time) / 1000 < config.SESSION_EXPIRESIN){
      dropCookie('vlomni-cookie-active', now.getTime(), config.SESSION_EXPIRESIN)
    }else{
      resetStorage();
      if (! ['/login', '/forgotPassword', '/newUser', '/confirmUser', '/vlomni/cookie/drop'].includes(this.props.location.pathname)){
        result = false
      }
    }    

    return result

  }

  init = this.once(function() {
    // this.check()
  })

  constructor(props){
    super(props);
    this.init()
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (returnCookieValue("vlomni-cookie-active") !== null){
        dropCookie("vlomni-cookie-active", new Date().getTime(), config.SESSION_EXPIRESIN)
      }
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }

  render() {
    const { classes, theme } = this.props;
    var db = secureStorage_getItem('database')
    var isSessionActive = this.check()
    var access = returnAccess()
    // if (!) window.location = '/login'
    
    return (
      <HttpsRedirect>
        <ErrorBoundary>
          <MutationObserver
            onContentChange={() => {dropCookie("vlomni-cookie-active", new Date().getTime(), config.SESSION_EXPIRESIN)}}
          >

            <Route path='/'>

              <Suspense fallback={<div style={{width:'100vw', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}><CircularProgress/></div>}>
                <div>
                  <Switch>
                    <Route exact path="/error" component={ErrorPage}/>
                    <Route path='/vlomni/cookie/drop' component={CookieDrop} />
                    <Route path='/login' component={LoginPage} exact />
                    <Route path='/forgotPassword' component={ForgotPassword} exact />
                    <Route path='/newUser' component={NewUser} exact />
                    <Route path='/vlomni-authorize-amazon-seller-central' component={props => {return isSessionActive?<ASCAuthS1 {...props} />:<LoginPage {...props}/>}} />
                    <Route path='/vlomni-authorize-amazon-vendor-central' component={props => {return isSessionActive?<ASCAuthS1 {...props} />:<LoginPage {...props}/>}} />
                    <Route path='/vlomni-amazon-api-authorize' component={ASCAuthS2} exact />
                    <Route path='/confirmUser' component={ConfirmUser} exact />
                    {
                      db === 'rds'
                        ? <Route key='rdsRoute' path='/' component={props => {return isSessionActive?<NavWrapper {...props} classes={classes} theme={theme}/>:<LoginPage {...props}/>}} />
                        : <Route key='dynRoute' path='/' component={props => {return isSessionActive?<NDNavWrapper {...props}/>:<LoginPage {...props}/>}} />
                    }
                  </Switch>
                </div>
              </Suspense>

            </Route>
          </MutationObserver>
        </ErrorBoundary>
      </HttpsRedirect>
    );
  }
}

export default withRouter((withStyles(styles, { withTheme: true })(App)));
