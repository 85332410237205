import React from 'react';
import { createTheme, ThemeProvider} from '@mui/material/styles'
import { grey, white } from '@mui/material/colors';

const AppThemeProvider = (props) => {
    const [mode, setMode] = React.useState('light');

    const lightMode = {
        primary: grey,
        divider: grey[300],
        background: {
            default: grey[900],
            primary: grey[800],
            customBtn: '#ccc',
            customBtn2: '#ff4500',
            order:"#0097ce",
            session:"#ff7302",
            product:"#825dbf",
            appBar:"#1b2539"
        },
        text:{
            primary: grey[900],
            secondary: grey[800],
            sideBorder: grey[300],
        },
        
    }

    const darkMode = {
        primary: grey,
        divider: grey[300],
        background: {
            default: grey[900]
        },
        text:{
            primary: grey[900],
            secondary: grey[800],
            sideBorder: grey[300],
        }
    }

    const theme = createTheme({
        palette: {
            mode,
            ...(mode === 'light' ? lightMode : darkMode),
        },
        typography: {
            allVariants: {
                fontFamily: 'Lato',
                fontSize: '14px',
                fontWeight: 400,
        },
    },
    })

    const toggleColorMode = () =>{
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
    }

    return (<ThemeProvider theme={theme}>{props.children}</ThemeProvider>)
}

export default AppThemeProvider;